import Router from './router/Router'
import axios from "axios"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useRef} from "react"

const App = () => {

    const errorCaughtRef = useRef(false)

    const tokenExpRef = useRef(false)

    const connectivityRef = useRef(false)

    const sweetAlert = withReactContent(Swal)

    const TO_MANY_REQUEST = 429

    const INVALID_TOKEN = 401

    axios.interceptors.response.use(
        response => response,
        error => {
            if (navigator.onLine === false && !connectivityRef.current) {
                connectivityRef.current = true
                sweetAlert.fire({
                    title: 'No Internet',
                    icon: 'error',
                    html: '<p>There is a problem with your internet.</p>',
                    confirmButtonText: 'Reload',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                })
            }

            if (error?.response) {
                const {status} = error?.response

                if (status === TO_MANY_REQUEST && !errorCaughtRef.current) {
                    errorCaughtRef.current = true
                    sweetAlert.fire({
                        title: 'Too Many Requests',
                        icon: 'error',
                        html: '<p>Your activity has been blocked as you have crossed the maximum number of requests. Please reload the page after 2 minutes.</p>',
                        confirmButtonText: 'Reload',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    })
                }

                if (status === INVALID_TOKEN && !tokenExpRef.current) {
                    tokenExpRef.current = true
                    sweetAlert.fire({
                        title: 'Session Expired!',
                        icon: 'error',
                        html: '<p>Your session has expired. Please log in again.</p>',
                        confirmButtonText: 'Login',
                        allowOutsideClick: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            localStorage.removeItem('accessToken')
                            localStorage.removeItem('userData')
                            localStorage.removeItem('_grecaptcha')
                            window.location.href = "/login"
                        }
                    })
                }
            }

            return Promise.reject(error)
        }
    )

    return <Router/>
}
export default App